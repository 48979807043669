import { datadogRum } from '@datadog/browser-rum';
import React, { ReactNode } from 'react';

interface Props {
  applicationId: string;
  clientToken: string;
  service: string;
  env: string;
  version: string;
  sampleRate: number;
  sessionReplaySampleRate: number;
  children: ReactNode;
}

const DataDog = (props: Props) => {
  const { applicationId, clientToken, service, env, version, sampleRate, sessionReplaySampleRate } =
    props;

  datadogRum.init({
    applicationId,
    clientToken,
    service,
    env,
    version,
    sampleRate,
    sessionReplaySampleRate,
    trackInteractions: false,
    silentMultipleInit: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [/https:\/\/.*\.trainual\.com/],
  });

  datadogRum.startSessionReplayRecording();

  return null;
};

export const DataDogRum = (props: Props) => <DataDog {...props} />;
